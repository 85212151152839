import resource from 'resource-axios';
import axios from '../baseCommodity';
import { config } from '../../config';

export const CommodityUserLogin = resource(config.api_commodity_login, {
    // fetchManageAndAssignCompanyToDealer: (params) => {
    //     return axios.get(`${config.admin_api_url}/admin/manage-and-assign-company-to-dealers`, params)
    // },

    // saveAndManageAndAssignCompanyToDealers: (params) => {
    //     return axios.post(`${config.admin_api_url}/admin/save-and-manage-and-assign-company-to-dealers`, params)
    // },

    // getCompanies: (params) => {
    //     var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    //     return axios.get(`${config.admin_api_url}/admin/companies?${queryString}`)
    // },

    // getUserNegativeList: (user_id) => {
    //     return axios.get(`${config.admin_api_url}/negative-list/user-negative-list/${user_id}`)
    // },

    // unblockNegativeList: (user_id, params) => {
    //     return axios.post(`${config.admin_api_url}/negative-list/unblock-user/${user_id}`, params)
    // },

    // userNegativeList: (user_id, params) => {
    //     var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    //     return axios.get(`${config.admin_api_url}/negative-list/user-negative-list/${user_id}?${queryString}`)
    // },

    // userReferenceList: (user_id, params) => {
    //     var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    //     return axios.get(`${config.admin_api_url}/users/${user_id}/reference-contacts?${queryString}`)
    // },

    // unblockNegativeWOAdCreditsList: (user_id, params) => {
    //     return axios.post(`${config.admin_api_url}/negative-list/unblock-user-without-adcredit/${user_id}`, params)
    // },

    // addToNegativeList: (user_id, params) => {
    //     return axios.post(`${config.admin_api_url}/negative-list/add-to-negative-list/${user_id}`, params)
    // },

    // fetchNegativeListReasons: (params) => {
    //     return axios.get(`${config.admin_api_url}/negative-list/reasons`, params)
    // },

    userRegister: (params) => {
        console.log({paramsInRegister:params})
        return axios.post(`${config.api_commodity_login}/register`, params)
    },
    userLogin: (params) => {
        return axios.post(`${config.api_commodity_login}/login`, params)
    },
    userLoginWithMobile: (params) => {
        return axios.post(`${config.api_commodity_login}/login-with-mobile-otp`, params)
    },

    contactNumberExistCheck: (params) => {
        return axios.post(`${config.api_commodity_login}/check-contact-number-exists`, params)
    },
    emailExistCheck: (params) => {
        return axios.post(`${config.api_commodity_login}/check-email-exists`, params)
    }, 


    // Search User by contacts
    // searchUserByContact: (params) => {
    //     var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    //     return axios.get(`${config.admin_api_url}/negative-list/search-user-by-contact-number?${queryString}`, params)
    // },

}, axios)