import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import MuiLink from "@mui/material/Link";
import { config } from "../../config";
import { Helmet } from "react-helmet";
import trackingEvents from "../../eventsTracking";
// import Footer from "../../footer";
import Footer from "../partials/Footer";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { uniqueId } from "lodash";
// import Heading from "./Heading";
// import RatePercent from "../console/admin/benchmark/RatePercent";
// import SmCardLoader from "./../partials/content-loaders/SmCardLoader";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { User } from "../../api/resources/User";
import Hidden from "@mui/material/Hidden";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LoadableVisibility from "react-loadable-visibility/loadable-components";
// import { BrowserRouter, Route, Routes } from "react-router-dom";

// import { ConsoleCommodity } from "../../api/resources/admin-api/ConsoleCommodity";
import { useLocation } from "react-router-dom";
// import PendingRating from "./PendingRating";
// import ReviewRatingDialog from "./ReviewRatingDialog";
import { cDecrypt, cEncrypt } from "../../helpers";
// import RegisterCard from "./RegisterCard";
import { Alert, Autocomplete, Button, Card, CardContent, CardMedia, CircularProgress, Divider, InputAdornment, Tab, Tabs, TextField, useMediaQuery } from "@mui/material";
// import IframeWindow from "./IframeWindow";
// import { Product } from "../../api/resources/Product";
// import AdSpace from "../AdSpace";
// import Carousel from "react-multi-carousel";
// import JSWFakeMessageAlert from '../JSWFakeMessageAlert';
// import FreeDeals202303Offer from "../FreeDeals202303Offer";
// import SeoListings from "../commodity-market-page/ListingPage";
// import { CommodityUser } from "../../api/resources/CommodityUser";
// import { CommodityProduct } from "../../api/resources/CommodityProduct";
import blinkingDot from '../../images/blinkingDot.gif'
// import KeyFeaturesSlider from "./KeyFeaturesSlider";
// import SignUpWithMobile from "./SignUpWithMobile";
import LazyLoadedIframe from "./LazyLoadedIframe";
import { CommodityCommon } from "../../api/resources/admin-api/CommodityCommon";
import SmCardLoader from "../partials/content-loaders/SmCardLoader";
import CarouselSlide from "./Carousel";
import ListingCarousel from "./ListingCarousel";
import Heading from "./Heading";
import CentralSearch from "../partials/CentralSearch";
import { debounce } from "lodash";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { fcmAnalytics } from "../../firebase";
import { ConsoleCommodity } from "../../api/resources/admin-api/ConsoleCommodity";
import { CommodityUser } from "../../api/resources/CommodityUser";
// import StartJourney from "./StartJourney";
// import AboutCoalShastra from './AboutCoalShastra'
// import BottomTabsNav from './../partials/BottomTabsNav'
var uaParser = require("ua-parser-js");

// const LatestFiveDeals = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-LatestFiveDeals" */ "./LatestFiveDeals"));
// const GreetUser = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-GreetUser" */ "./GreetUser"));
// const MostActiveContracts = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-MostActiveContracts" */ "./MostActiveContracts"));
// const BannersCarousel = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-BannersCarousel" */ "./BannersCarousel"));
// const CoalOrigins = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-CoalOrigins" */ "./CoalOrigins"));
// const FavoriteContracts = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-FavoriteContracts" */ "./FavoriteContracts"));
// const Benchmarks = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-Benchmarks" */ "./Benchmarks"));
// const GlobalCoalNews = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-GlobalCoalNews" */ "./GlobalCoalNews"));
// const PromptIOSAppDownloadWeb = LoadableVisibility(() =>
	// 	import(/* webpackChunkName: "js/chunks/components-app-download-banners-PromptIOSAppDownloadWeb" */ "../app-download-banners/PromptAppDownload")
// );
// const NewFavCard = LoadableVisibility(() =>
	// import("./FavNewCard")
// );

// const Partners = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-Partners" */ "./Partners"));

// const AppDownloadCTA = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-AppDownload" */ "./AppDownload"));
// const WhyCoalShastra = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-WhyCoalShastra" */ "./WhyCoalShastra"));
// const Testimonials = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-Testimonials" */ "./Testimonials"));
// const SignUpCTA = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-SignUpCTA" */ "./SignUpCTA"));
const BottomTabsNav = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/partials-BottomTabsNav" */ "./../partials/BottomTabsNav"));
const SignUpWithMobile = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-SignUpWithMobile" */ "./SignUpWithMobile"));
const TrendingVesselsMines = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-TrendingVesselsMines" */ "./TrendingVesselsMines"));
const AboutCoalShastra = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-AboutCoalShastra" */ "./AboutCoalShastra"));
const KeyFeaturesSlider = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-KeyFeaturesSlider" */ "./KeyFeaturesSlider"));
const StartJourney = LoadableVisibility(() => import(/* webpackChunkName: "js/chunks/homepage-v3-StartJourney" */ "./StartJourney"));


const useStyles = makeStyles((theme) => ({
	item: {
		// [theme.breakpoints.down('md')]: {
		flexShrink: 0,
		scrollSnapAlign: "start",
		// padding: theme.spacing(1, 2)
		// }
	},
}));

const usePrevious = (value) => {
	const ref = React.useRef();
	React.useEffect(() => {
		ref.current = value;
	})
	return ref.current;
} 
export default function Index() {
	const classes = useStyles();
	const navigate = useNavigate();
	const isLoggedIn = useSelector((state) => state.userReducer.isLoggedIn);
	const user = useSelector((state) => state.userReducer.user);
	// const userReducer = useSelector((state) => state.userReducer);
	// const user_mode = useSelector(state => state.userReducer.user_mode);
	// const globalSetting = useSelector((state) => state.commonReducer.globalSettings);
	const [showDownloadPrompt, showDownloadPromptSet] = React.useState(false);
	// const [pendingRatingData, setPendingRatingData] = React.useState("");
	// const matchesViewport = useMediaQuery("(max-width:580px)");
	const location = useLocation();
	const guestNumberVerified = sessionStorage.getItem("data") !== null;
	// console.log('guestNumberVerified', guestNumberVerified)
	// const [companyId, setCompanyId] = React.useState("");
	// const [rating, setRating] = React.useState({});
	// const [rewards, setRewards] = React.useState([]);
	// const [remarks, setRemarks] = React.useState("");
	// const [ratingLoading, ratingLoadingSet] = React.useState(false);
	const [youtubeID,setYoutubeID] = React.useState('FOcuNJ_R-TY')
	// const [adData, setAdData] = React.useState([]);
	// const [adDataMid, setAdDataMid] = React.useState([]);
	// const [adDataTop, setAdDataTop] = React.useState([]);
	// const [adDataBottom, setAdDataBottom] = React.useState([]);
	// const [guestBenchmarkListLoading, setGuestBenchmarkListLoading] = React.useState(false);
	// const [listingPage, setListingPage] = React.useState([]);
	// const [openSeoListingPage, setOpenSeoListingPage] = React.useState(false);
	// const [benchmarkDetailsLoader, setBenchmarkDetailsLoader] = useState(false);
	// const [benchmarkListGuest, benchmarkListGuestSet] = React.useState({});
	const [buyListings, setBuyListings] = React.useState([]);
	const [sellListings, setSellListings] = React.useState([]);
	const [listingLoading, setListingLoading] = React.useState(false);
	const [open, setOpen] = React.useState("");
	const [selectedTab, setSelectedTab] = React.useState("buy");

	const [contracts, setContracts] = React.useState([]);
	const [loadingContracts, setLoadingContracts] = React.useState(false);
	const [searchKeyword, setSearchKeyword] = React.useState("")
	const debouncedHandler = React.useCallback(debounce((f, v) => { f(v) }, 600), []);
	const matchesViewport = useMediaQuery("(max-width:600px)");
	const [parsedUA, setParsedUA] =  React.useState(new uaParser())
	// const prevSearchKeyword = usePrevious(searchKeyword)

	// const getPendingRatings = () => {
	// 	User.getPendingRatings()
	// 		.then((response) => {
	// 			setPendingRatingData(response.data.data);
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 		})
	// 		.finally(() => {});
	// };

	/**
	 * @function getRatings
	 * @name getRatings
	 * @description
	 * An async function to get ratings from company
	 *
	 * @async
	 */

	// const getRatings = (rating_id) => {
	// 	ratingLoadingSet(true);

	// 	User.getRating({ rating_id: rating_id })
	// 		.then((response) => {
	// 			if (response.data.status == "success") {
	// 				setCompanyId(response.data.data.rating.company_from);
	// 				setRating(response.data.data.rating);
	// 				setRewards(response.data.data.rating.rewards);
	// 				setRemarks(response.data.data.rating.remarks);
	// 				ratingLoadingSet(false);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 		})
	// 		.finally(() => {
	// 			ratingLoadingSet(false);
	// 		});
	// };

	React.useEffect(() => {
		const parsedUA = new uaParser();
		var browser = parsedUA.getBrowser();
		var os = parsedUA.getOS();
		var device = parsedUA.getDevice();
		if (
			// browser.name == 'Chrome' ||
			browser.name == "Mobile Safari" ||
			browser.name == "Safari" ||
			os.name == "Mac OS" ||
			device.type == "Apple"
		) {
			showDownloadPromptSet(true);
		}
		if (isLoggedIn) {
			// getPendingRatings();
			if (location.search) {
				// getRatings(cDecrypt(location.search.split("?rating_id=")[1]));
			}
		}
	}, [isLoggedIn, location]);

	// const fetchGuestBenchmarkList = async (params) => {
	// 	setGuestBenchmarkListLoading(true);
	// 	var params = Object.assign(
	// 		{
	// 			//   keyword: searchKeyword,
	// 		},
	// 		params
	// 	);

	// 	try {
	// 		const res = await User.guestBenchmarkList(params);
	// 		if (res.data.status === "success") {
	// 			benchmarkListGuestSet(res.data.data);
	// 			// setBenchmarkID(res.data.data.id)
	// 		}
	// 	} catch (e) {
	// 		console.error(e);
	// 	}
	// 	setGuestBenchmarkListLoading(false);
	// };

	// React.useEffect(() => {
	// 	fetchGuestBenchmarkList();
	// }, []);

	// const fetchAdSpaceData = async (params) => {
	// 	setGuestBenchmarkListLoading(true);
	// 	var params = Object.assign(
	// 		{
	// 			platform_type: 2,
	// 			placement: "1,2,3",
	// 			page: "homepage",
	// 		},
	// 		params
	// 	);

	// 	try {
	// 		const res = await User.adSpaceData(params);
	// 		if (res.data.status === "success") {
	// 			//console.log(res.data?.data?.adSpaces?.filter((a) => a.placement == 1));
	// 			setAdData(res.data?.data?.adSpaces);
	// 			setAdDataMid(res.data?.data?.adSpaces?.filter((a) => a.placement == 2));
	// 			setAdDataTop(res.data?.data?.adSpaces?.filter((a) => a.placement == 1));
	// 			setAdDataBottom(res.data?.data?.adSpaces?.filter((a) => a.placement == 3));
	// 		}
	// 	} catch (e) {
	// 		console.error(e);
	// 	}
	// 	setGuestBenchmarkListLoading(false);
	// };

	// React.useEffect(() => {
	// 	// fetchAdSpaceData()
	// }, []);

	// const getListingData = (listingId) => {
		// Product.viewProductDetails({
		//     listing_id: listingId
		// })
		//     .then(response => {
		//         if (response.data.status == "success") {
		//             navigate(`/markets?market_code=${response.data.data && response.data.data?.market?.market_code}&contract_code=${response.data.data?.contract?.vessel_code}`)
		//         }
		//     })
		//     .catch(error => {
		//         console.log(error);
		//     });
	// };
	//     const getListings=async()=>{
	// //         try{
	// //            const res=await CommodityUser.fetchListings()
	// //             if(res.status===200){
	// //                 console.log("incoming: ",res)
	// //                 setListingPage(res.data.data.listings)
	// //             }
	// //         }
	// //         catch(e){
	// // console.log('listing errror',e)
	// //         }
	//     }
	//     React.useEffect(()=>{
	//         getListings()
	//     },[])
	const fetchBuyListings = async () => {
		setListingLoading(true)
		let response = null;
		try {
			if(isLoggedIn) {
				response = await CommodityUser.latestBuyListingsUser();
			} else {
				response = await CommodityCommon.latestBuyListings();
			}
			// console.log('buy res',{response})
			if(response.status == 200) {
				console.log('set buy listings', response.data.data)
				setBuyListings(response.data.data);
			}
		} catch (error) {
			console.log('error in fetching buy listings', error)
		} finally {
			setListingLoading(false)
		}
		// setListingLoading(false)
	}
	const fetchSellListings = async () => {
		setListingLoading(true);
		let response = null;
		try {
			if(isLoggedIn) {
				response = await CommodityUser.latestSellListingsUser();
			} else {
				response = await CommodityCommon.latestSellListings();
			}
			// console.log('sell res',{response})
			if(response.status == 200) {
				// console.log('set sell listings', response.data.data)
				setSellListings(response.data.data);
			}
		} catch (error) {
			console.log('error in fetching sell listings', error)
		} finally {
			setListingLoading(false)
		}
	}

	const searchContracts = async () => {
		setLoadingContracts(true)
		var params = {
			"search[keyword]" : searchKeyword,
			page: 1,
			limit: 50
		}
		try {
			const res = await CommodityCommon.Contracts(params);
			console.log({resCOnts:res})
			const contractsArray = res.data.data.contracts;
			if(res.status == 200) {
				if(contractsArray.length > 0) {
					setContracts(contractsArray);
					setLoadingContracts(false)
				} else {
					console.log('no contracts found')
                    setLoadingContracts(false)
				}
			}
		} catch (error) {
			console.log('error while fetching contracts', error)
			setLoadingContracts(false)
		}
		setLoadingContracts(false)
	}

	React.useEffect(() => {
		// searchContracts();
		fetchBuyListings();
		fetchSellListings();
	}, []);

	React.useEffect(() => {
		// console.log({searchKeyword})
		if(
			// prevSearchKeyword != undefined &&
			 searchKeyword != '' && searchKeyword.length > 2)
			debouncedHandler(searchContracts)
	}, [searchKeyword])

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue)
	}

	const handleSearchItemClick = (contract) => {
		console.log('contract clicked', contract)
		navigate(`markets/${contract.commodity.commodityType.slug}?name=${contract.commodity.slug}&id=${cEncrypt(contract._id)}`);
		trackingEvents.searchItemClick({contract: contract.name, commodity: contract.commodity.name})
	}

	return (
		<>
			<Box  >
				{/* SEO */}

				<Helmet>
					<title>Home / commodity trade / Buyers and sellers / bulk trade / horticulture / plants / fruiting plants</title>
					<meta
						name="description"
						content="We at Coalshastra aim to provide you the best B2B online marketplace to buy and sell coal. TRUST us to level up your margin game with our professional support."
					/>
					<link rel="canonical" href={config.base_url} />
					<script type="application/ld+json">
						{`"@context": "http://www.schema.org",
                        "@type": "Organization",
                        "name": "Coalshastra",
                        "email": "info@coalshastra.com",
                        "url": "https://www.coalshastra.com",
                        "logo": "https://www.coalshastra.com/images/coalshastra-logo-white.png",
                        "description": "",
                        "sameAs" : [
                            "https://www.facebook.com/coalshastra/",
                            "https://twitter.com/CoalShastra",
                            "https://www.linkedin.com/company/coalshastra.com"
                        ],
                        "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "Pitam Pura",
                        "addressRegion": "Delhi",
                        "postalCode": "110034",
                        "addressCountry": "India"
                        },
                        "contactPoint": {
                        "@type": "ContactPoint",
                        "telephone": "011 4552 8444",
                        "contactType": "customer service"
                        }`}
					</script>
					<script type="application/ld+json">
						{` "@context" : "http://schema.org",
                        "@type" : "WebSite",
                        "name" : "CoalShastra",
                        "url" : "http://coalshastra.com"  `}
					</script>
					<script type="application/ld+json">
						{`"@context": "https://schema.org",
                        "@graph":
                        [
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Login",
                                "url": "https://www.coalshastra.com/login"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Register",
                                "url": "https://www.coalshastra.com/sign-up"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "About Us",
                                "url": "https://www.coalshastra.com/about-us"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Blog",
                                "url": "https://www.coalshastra.com/blog"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Mediakit",
                                "url": "https://www.coalshastra.com/media-kit"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "News",
                                "url": "https://www.coalshastra.com/news"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Media and Events",
                                "url": "https://www.coalshastra.com/events"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Contact Us",
                                "url": "https://www.coalshastra.com/contact"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Indexes",
                                "url": "https://www.coalshastra.com/global-indexes"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Auction",
                                "url": "https://www.coalshastra.com/auction"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Request for Quotation",
                                "url": "https://www.coalshastra.com/requirement/buyer"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Seller Listings Map",
                                "url": "https://www.coalshastra.com/seller-map"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "New Listings",
                                "url": "https://www.coalshastra.com/requirement/seller"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Buyers RFQ's Map",
                                "url": "https://www.coalshastra.com/listings"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Unit Converter",
                                "url": "https://www.coalshastra.com/tools/unit-converter"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Basis Converter",
                                "url": "https://www.coalshastra.com/tools/basis-converter"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Ratio Converter",
                                "url": "https://www.coalshastra.com/tools/ratio-calculator"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Slagging & Fouling Calculator",
                                "url": "https://www.coalshastra.com/tools/slagging-and-fouling"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Blending Calculator",
                                "url": "https://www.coalshastra.com/tools/blending-calculator"
                            },
                            {
                                "@context": "https://schema.org",
                                "@type":"SiteNavigationElement",
                                "@id":"#table-of-contents",
                                "name": "Rail Freight Calculator",
                                "url": "https://www.coalshastra.com/tools/rail-freight-calculator"
                            }
                        ]`}
					</script>
				</Helmet>
				{/* {showDownloadPrompt && <PromptIOSAppDownloadWeb open={showDownloadPrompt} = React.useState(""); />} */}
				{/**
				 * TODO add space
				 */}
				{/* <JSWFakeMessageAlert /> */}
				{/* <FreeDeals202303Offer /> */}
				{/* {adDataTop.length > 0 && <AdSpace key="top" adSpace={adDataTop[0]} />} */}
				{/* Latest five deals */}
				{/* <Hidden mdUp>
					<Box sx={{ background: "#fff", px: 1, pt: 1, pb: 0 }}>
						<Typography variant="h6" component="h1">
							<strong>Latest Deals:</strong>
						</Typography>
					</Box>
				</Hidden> */}
				{/* <Box sx={{background: "#EEF2F5", p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 5, overflow: 'auto', scrollSnapType: 'x mandatory'}}> */}


{/* hiding latest deals as of now */}

				{/* <Grid container alignItems={'center'} p={2} >
					<Grid item xs={12} sm={2}>
						{<Typography variant="h6" component="h1">
							<strong>Latest Deals:</strong>
						</Typography>}
					</Grid>
					<Grid item xs={12} sm={10}>
					<LatestFiveDeals events={eventsTracking} user={user && user.id} />
					</Grid>
				</Grid> */}

			

				{/* Guest users */}
				{/* <Button onClick ={()=>setOpen('buy')} >buy</Button>
				<Button onClick ={()=>setOpen('sell')} >sell</Button> */}
				{/* <Box sx={{ marginRight: "10px", border:'2px solid red' }} >
					<h1>{buyListings.name}</h1>
					{buyListings.length > 0 && buyListings.map(l => {
							<p>
							{l.name}
							<BuyListingItem data={l} key={l.listingNumber} />
							</p>
						})}
						</Box> */}
						{/* {
							open === 'buy' && 
							<ListingCarousel listings = {buyListings} isLoading={listingLoading} />
						}
						{
							open === 'sell' &&
							<ListingCarousel listings = {sellListings} isLoading={listingLoading} />
						}	 */}

						{/* <CentralSearch 
						contracts = {contracts}
						loadingContracts = {loadingContracts}
						setSearchKeyword = {setSearchKeyword}
						/> */}

						<Box
							mt={2}
							textAlign="center"
							sx={{ width: { xs: '100%', sm: 400 }, mx: 'auto' }}
						>
							<Autocomplete
							id="tags-outlined"
							loading={loadingContracts}
							style={{ padding: '4px' }}
							options={searchKeyword.length > 2 ? contracts : []}
							onInputChange={(event, newKeyword) => {
								if (newKeyword.length > 1) {
								setSearchKeyword(newKeyword);
								}
							}}
							renderOption={(props, contract) => (
								<Box component="li" {...props} onClick={()=>handleSearchItemClick(contract)}>
								<Grid container spacing={1}>

									<Grid item sm={3}>
										<Box style={{height: '60px', overflowY: 'hidden'}}>
										{(contract.images && contract.images.length > 0) 
										? <img style={{width: '100%'}} src={`${process.env.REACT_APP_API_URL_COMMODITY}/${contract.images[0].url}`} alt={contract.name} title={contract.name} />
										: <Box style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ccc', color: '#111'}}>
											<small>No Image</small>
										</Box>}
										</Box>
									</Grid>
									<Grid item sm={9}>
									<Grid container>
										<Grid item sm={6}>
										<Typography variant="h6">
											{contract?.name}
										</Typography>
										</Grid>
										<Grid item sm={6} textAlign="right">
										<Typography variant="caption">
											{contract?.commodity?.commodityType &&
											contract?.commodity?.commodityType
												?.name && (
												<>
												{
													contract?.commodity?.commodityType
													?.name
												}
												{' '}
												{' '}
												</>
											)}
										</Typography>
										<Typography variant="overline">
											{contract?.commodity?.name}
										</Typography>
										</Grid>
										<Grid item sm={12}>
										<Typography variant="body2">
											{contract.clusterName.plural}:{' '}
											{contract?.publishedClusters
											.map((pc) => pc.name)
											.join(', ')}
										</Typography>
										</Grid>
									</Grid>
									</Grid>
									{/* </NavLink> */}
								</Grid>
								</Box>
							)}
							filterOptions={(x) => x}
							//   onChange={(e, data) => {
							//     //   setcontractData(data)
							//     //   clusterSet(null);
							//     //   vesselsSet(data.publishedClusters);
							//   //   console.log({ data });
							//   }}
							getOptionLabel={(option) => option.name}
							//   value={contractData}
							filterSelectedOptions
							size="small"
							renderInput={(params) => (
								<TextField
								{...params}
								onClick={()=>trackingEvents.homeSearchbarClick()}
								label="Find Products"
								placeholder="Find Products"
								variant="outlined"
								fullWidth
								size="small"
								value={searchKeyword}
								InputProps={{
									...params.InputProps,
									endAdornment: (
									<InputAdornment position="end">
										{loadingContracts ? (
										<CircularProgress color="inherit" size={20} />
										) : 
										<>
											{/* {params.InputProps.endAdornment} */}
											<SearchIcon />
											{searchKeyword.length > 0 && 
											<Button onClick={()=>setSearchKeyword('')}>
												<CloseIcon />
											</Button>
											}
										</>
										}
									</InputAdornment>
									),
								}}
								/>
							)}
							/>
						</Box>
						 <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 2 }}>
							<Tabs value={selectedTab} onChange={handleTabChange}>
								<Tab label="Latest Buyers" value='buy' sx={selectedTab==='buy'?{backgroundColor :"#F78702", color:'#fff', "&:hover":{backgroundColor:"#fff"}}:{}} onClick={()=>trackingEvents.latestBuyersTabClick()} />
								<Tab label="Latest Sellers" value='sell' sx={selectedTab==='sell'?{backgroundColor :"#F78702", color:'#fff',  "&:hover":{backgroundColor:"#fff", color:"#19243B"}}:{}} onClick={()=>trackingEvents.latestSellersTabClick()}/>
							</Tabs>
						</Box>
						<Box sx={{}}>
							{selectedTab === 'buy' && <ListingCarousel listings = {buyListings} isLoading={listingLoading} /> }
							{selectedTab === 'sell' && <ListingCarousel listings = {sellListings} isLoading={listingLoading} /> }
						</Box>
				{/* <CarouselSlide >
						{
						// listingLoading 
							// ? [1, 2, 3, 4].map((i) => (
							// 		<Box sx={{ marginRight: "10px" }} key={i}>
							// 			<SmCardLoader key={i} loaderProps={{ height: "115px", width: "200px" }} />
							// 		</Box>
							//   ))
							// :
							}
					</CarouselSlide> */}
				{<SignUpWithMobile user={user} />}
				{!isLoggedIn && (
					<>
						{!guestNumberVerified && (
							<>
								{" "}
								<Container>
									<Grid container spacing={2}>
										<Grid item sm={12} xs={12}>
											<Hidden smUp>
												<Box textAlign="center" sx={{ my: { xs: 4 } }}>
													
                                                <Box style={{marginTop:2}}>
                                                <Grid container style={{ borderRight: 'thin solid #ddd', justifyContent: 'center', alignItems: 'center' }}>
  <Grid item >
    <Box style={{display:"flex",justifyContent:"space-between"}}>
      <Box style={{marginTop:5}}>
      {/* <img src={blinkingDot} style={{ width: 15, height: 15,paddingRight:5,paddingTop:5,marginTop:5 }} alt="Blinking Dot" /> */}
	  <Box style={{width:10, height:10, backgroundColor:'#29bd44', borderRadius:'50%', paddingRight:5,paddingTop:5,marginTop:10, marginRight:5}}></Box>
      </Box>
     <Box>
     <Typography variant="h4" component="h2" display="inline-block" gutterBottom>
        <strong style={{ color: '#19243B' }}>Live</strong>
      </Typography>
     </Box>
    </Box>
  </Grid>
</Grid>

  
<Grid container style={{justifyContent: 'center', alignItems: 'center' }}>
  <Box sx={{ color: '#6A6A6A', py: 0.5, fontSize: '16px' }}>
    Prices
  </Box>
</Grid>

</Box>
<Divider />
<Box style={{ borderRight: 'thin solid #ddd',marginTop:2 }}>
                                                    <Typography variant="h4" component="h2" display="block" gutterBottom>
  <strong style={{ color: '#19243B' }}>&lt; 30 Secs</strong>
</Typography>

                                                        <Box sx={{ color: '#6A6A6A', py: .5, fontSize: '16px' }}>
                                                            Deal
                                                        </Box>
                                                    </Box>
													<Divider />
                                                    <Box style={{marginTop:2}}>
                                                        <Typography variant="h4" component="h2" display="block" gutterBottom>
                                                            <strong style={{ color: '#19243B' }}>24x7</strong>
                                                        </Typography>
                                                        <Box sx={{ color: '#6A6A6A', py: .5, fontSize: '16px' }}>
                                                            Support
                                                        </Box>
                                                    </Box>
													<Divider />
													{/* <Box sx={{ my: 3 }}>
														<Typography variant="h4" component="h2" display="block" gutterBottom>
															<strong style={{ color: "#2959BA" }}>8500+</strong>
														</Typography>
														<Box sx={{ color: "#6A6A6A", py: 0.5, fontSize: "16px" }}>Clients</Box>
													</Box>
													<Divider />
													<Box sx={{ my: 3 }}>
														<Typography variant="h4" component="h2" display="block" gutterBottom>
															<strong style={{ color: "#2959BA" }}>6000+</strong>
														</Typography>
														<Box sx={{ color: "#6A6A6A", py: 0.5, fontSize: "16px" }}>Deals</Box>
													</Box>
													<Divider />
													<Box sx={{ mt: 3 }}>
														<Typography variant="h4" component="h2" display="block" gutterBottom>
															<strong style={{ color: "#2959BA" }}>3 Mn MT</strong>
														</Typography>
														<Box sx={{ color: "#6A6A6A", py: 0.5, fontSize: "16px" }}>Quantity Transacted</Box>
													</Box> */}
												</Box>
											</Hidden>
											<AboutCoalShastra />
											{/* <Partners /> */}
										</Grid>
									</Grid>
								</Container>
								<Box sx={{ background: "#EEF2F5" }} >
									
									<StartJourney />
								</Box>
						
								<Box sx={{ background: "#00838F", pt: { xs: 8, sm: 3 }, pb: { xs: 5, sm: 4 } }} >
								<KeyFeaturesSlider />
								</Box>
								<Box>		<Hidden smUp>
						<BottomTabsNav />
					</Hidden></Box>
								{/* <Box sx={{ py: 4 }}>
									<Testimonials />
								</Box>
								<Box sx={{ background: "#EEF2F5" }}>
									<AppDownloadCTA />
								</Box> */}
							</>
						)}
					</>
				)}

			
				{/* Banners */}

				{/* {(isLoggedIn || guestNumberVerified) && (
					<BannersCarousel
						events={eventsTracking}
						// guestNumberVerified={guestNumberVerified}
						user={user && user.id}
					/>
				)} */}

				{(guestNumberVerified || isLoggedIn) && (
					<Container>
						<Grid container spacing={2} >
							<Grid item sm={12} xs={12}>
								{/* Trending Vessel/Mine (last 7 days) */}
								<TrendingVesselsMines guestNumberVerified={guestNumberVerified} user={user && user.id} />
							</Grid>
							<Grid item sm={12} xs={12} className="pointer" style={{display:"flex",justifyContent:"center",alignItems:"center",marginBottom:"2vh",marginTop:"-4vh"}}>
							{/* <a href="https://www.youtube.com/watch?v=FOcuNJ_R-TY" target="_blank"><img src="src/images/thumbnailNew.webp" alt='youtube thumbnail' /></a> */}
							{/* <iframe  
 id="ytplayer"
 allowfullscreen="allowfullscreen"
 mozallowfullscreen="mozallowfullscreen" 
 msallowfullscreen="msallowfullscreen" 
 oallowfullscreen="oallowfullscreen" 
 webkitallowfullscreen="webkitallowfullscreen"
 type="text/html"
 width="55%"
 height="450"
 style={{borderRadius: "4%",boxShadow:"rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",overflow: "hidden"}}
 frameBorder="0"
        // sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
        src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}>
</iframe> */}
							</Grid>
							{/* for lazy loading the youtube video dialog */}
							<LazyLoadedIframe youtubeID={youtubeID} />
						</Grid>
						
					<Hidden smUp>
						<BottomTabsNav />
					</Hidden>
					</Container>
				)}
<>
				{/* {(guestNumberVerified || isLoggedIn) &&
                <Container>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Heading title={'Coal Origin'} />
                                <CoalOrigins
                                    events={eventsTracking}
                                    guestNumberVerified={guestNumberVerified}
                                    user={user && user.id}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            } */}

				{/* {isLoggedIn &&
                <Container>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12}> */}

				{/* Benchmarks */}
				{/* {(userReducer.noCompany) ? */}
				{/* <Benchmarks
                                    user={user}
                                    guestNumberVerified={guestNumberVerified}
                                    user_mode={user_mode}
                                    events={eventsTracking}
                                /> */}
				{/* : */}
				{/* <Box sx={{ mb: 4 }}>
                                    <Heading title={'Benchmarks'} />
                                    <Box sx={{ display: 'flex', overflow: 'auto', scrollSnapType: 'x mandatory', flexBasis: '40%', pb: { sm: 3 } }}>
                                        {guestBenchmarkListLoading && [0, 1, 2, 3, 4].map((i) => (
                                            <Box sx={{ marginRight: '10px' }} key={i} >
                                                <SmCardLoader key={i}
                                                    loaderProps={{ height: "115px", width: '200px' }}
                                                />
                                            </Box>
                                        ))}
                                        {(!guestBenchmarkListLoading && benchmarkListGuest) &&
                                            <Card variant="outlined" sx={{ mr: 1, '&:hover': { boxShadow: '1px 10px 16px #ddd' } }} className={classes.item} key={uniqueId("bm_")}>
                                                <CardContent sx={{ p: 1 }}>
                                                    <Box sx={{ color: '#6A6A6A', py: .5, fontSize: '13px' }}>
                                                        <img src={benchmarkListGuest?.country?.flag_img} alt="flag" width="20" />&nbsp;
                                                        &nbsp;
                                                        {benchmarkListGuest?.country?.iso} | &nbsp;

                                                        {benchmarkListGuest.quality_from} {benchmarkListGuest.quality_type_text} - {benchmarkListGuest.quality_to} {benchmarkListGuest.quality_type_text}
                                                    </Box>
                                                    <Box sx={{ fontSize: '14px', fontWeight: '600', mb: 1 }}>{benchmarkListGuest.name}</Box>
                                                    <Box sx={{ fontWeight: 'bold', fontSize: '15px' }}>
                                                        <img src="../images/rupee-icon.png" alt='INR' width="10" />
                                                        {new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(benchmarkListGuest.current_week_price)} &nbsp;
                                                        <RatePercent percentage={benchmarkListGuest.price_percentage} />
                                                        <IconButton
                                                            component={Link}
                                                            to={`/guest/benchmark`}
                                                            size="small" color='primary' sx={{ verticalAlign: 'sub' }}>
                                                            <ChevronRightIcon fontSize='small' className="vAlignBottom" />
                                                        </IconButton>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        }
                                        <RegisterCard label={`benchmarks`} />
                                    </Box>
                                </Box> */}
				{/* } */}
				{/* </Grid>
                    </Grid>
                </Container>
            } */}

				{/* Global coal news */}
				{/* Render widget if enabled, ie; value is 1 */}
				{/* {globalSetting.settings_steelmint_widget_enable == 1 && (
					<Container maxWidth="lg">
						<Box sx={{ mt: { sm: 4, xs: 2 }, mb: { sm: 7, xs: 4 } }}>
							<IframeWindow src="/steelmint-widget.html" />
						</Box>
					</Container>
				)} */}
				{/* <Box sx={{ mt: { sm: 4, xs: 2 }, mb: { sm: 7, xs: 4 } }} >
					<GlobalCoalNews events={eventsTracking} user={user && user.id} />
				</Box> */}

				{/* {!isLoggedIn && (
					<Box sx={{ background: "#2959BA" }}>
						<SignUpCTA />
					</Box>
				)} */}

				{/**
				 * TODO add space
				 */}
				{/* {adDataBottom.length > 0 && <AdSpace key="bottom" adSpace={adDataBottom[0]} />} */}

            {/* {isLoggedIn && <Box sx={{ backgroundColor: '#2959BA', py: 1.5, textAlign: 'center', mt: 2 }}>
                <MuiLink
                    // color="primary"
                    underline='none'
                    component={Link}
                    to={`/markets`}
                    size="small"
                    onClick={e => { eventsTracking.exploreMarkets(user?.id ? user?.id : "Unregistered_user") }}
                    sx={{ fontWeight: 'bold', fontSize: '18px', color: '#fff' }}
                >
                    Explore Coal Markets <ChevronRightIcon fontSize='small' className="vAlignMiddle" />
                </MuiLink>
            </Box>} */}

				{/* {pendingRatingData && pendingRatingData.length > 0 && <PendingRating pendingRatingData={pendingRatingData} />} */}

				{/* {user && user.active_company == companyId && rewards && rewards.length > 0 && (
					<ReviewRatingDialog rewards={rewards} rating={rating} remarks={remarks} loading={ratingLoading} />
				)} */}
</>
				<Box >
					<Footer/>
				</Box>
			</Box>
		
			<Container >
					{/* <Grid container spacing={2}> */}
						{/* <Grid item sm={12} xs={12}> */}
							{/* My favorite contracts - for authenticated users */}
							 {/* {(isLoggedIn && !userReducer.noCompany) &&  */}
                            {/* <FavoriteContracts */}
                                {/* events={eventsTracking} */}
                                {/* user={user && user.id} */}
                            {/* /> */}
                        {/* } / */}

							{/* Most active contracts of the day */}
							{/* {isLoggedIn && <NewFavCard events={eventsTracking} guestNumberVerified={guestNumberVerified} user={user && user.id} />}
							{!isLoggedIn && guestNumberVerified && <NewFavCard events={eventsTracking} guestNumberVerified={guestNumberVerified} user={user && user.id} />} */}

							{/**
							 * TODO add space
							 */}
							{/* {adDataMid.length > 0 && <AdSpace key="mid" adSpace={adDataMid[0]} />} */}
						{/* </Grid> */}
					{/* </Grid> */}
					
				</Container>

		</>
	);
}
