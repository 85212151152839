import React from "react";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import "../sass/app.scss";
import { connect } from "react-redux";
import { SnackbarProvider } from 'notistack';
import { config } from '../config';
import { socket } from '../socket';
import { checkAndLoadUser } from "../redux/actions/userActions";
// import { LANGUAGES } from "../redux/actions/types";
import {
    loader,
    loadCommonData,
    loadLanguageTranslations,
    loadAvailableLanguages,
    topProgressBarStatus,
} from "../redux/actions/commonActions";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { Extras } from "../api/resources/Extras";
import { LSQSessionTrack, isDevEnv, getUserToken } from "../helpers";
import LinearProgress from "@mui/material/LinearProgress";
import Loader from "./partials/Loader"
import SwitchRoutes from "./partials/SwitchRoutes"
import FirebaseNotification from "./firebase/FirebaseNotification";
import UnauthorizedAndPendingGSTModal from "./partials/UnauthorizedAndPendingGSTModal";
import TopBidOfferSuggestionContainer from './partials/top-bid-offer-suggestion-modal/TopBidOfferSuggestionContainer'
import FloatingContactForm from "./partials/FloatingContactForm";
import { sellerTheme, buyerTheme } from "../theme";
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';
import axios from '../api/base'
// const ScrollToTop = React.lazy(() => import( /* webpackChunkName: "js/chunks/partials-ScrollToTop" */ "./partials/ScrollToTop"));
// const ToastNotification = React.lazy(() =>
//     import(
//         /* webpackChunkName: "js/chunks/partials-ToastNotification" */ "./partials/ToastNotification"
//     )
// );

// import { loadReCaptcha } from 'react-recaptcha-v3'

import { initFirebase } from "../firebase";
// import TestCheckAndLoaduser from "./TestCheckAndLoadUser";

class CoalShastra extends React.Component {
    componentDidMount() {
        // loadReCaptcha("6LdIZ80UAAAAAPEsHgAULnhqwDzA5mbzyAq-Uggl");

        initFirebase();
        this.props.loader(false);
        this.props.topProgressBarStatus(false);

        if(this.props.isLoggedIn)
        {
            // this.props.checkAndLoadUser();
            // this.connectWebSocket();
        }

        // this.loadAvailableLanguages();
        this.props.loadCommonData();
        //let user = this.props.user
    }

    // componentWillUnmount() {
    //     socket.disconnect();
    // }

    componentDidUpdate(prevProps) {
        // window.Pusher = Pusher;

        let gaOptions = {}
        if(this.props.isLoggedIn && this.props.user)
        {
            gaOptions = {
                userId: this.props.user.id
            }
        }
        if(process.env.REACT_APP_ENV == 'production' && process.env.NODE_ENV == 'production') {
            ReactGA.initialize([
                {
                    trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
                    gaOptions: gaOptions
                },
                {
                    trackingId: process.env.REACT_APP_GOOGLE_FIREBASE_G4_ID,
                    gaOptions: gaOptions
                },
                // process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
                // 'name': process.env.REACT_APP_ENV + "Tracker",
                // // debug: (process.env.NODE_ENV === 'development' && (process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging'))
            ]);
        } else {
            ReactGA.initialize([
                {
                    trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
                    gaOptions: gaOptions
                }
            ])
            // ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
            //     'name': process.env.REACT_APP_ENV + "Tracker",
            //     // debug: (process.env.NODE_ENV === 'development' && (process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging'))
            //     gaOptions: gaOptions
            // });
        }

        let echoProps = {
            broadcaster: 'pusher',
            key: process.env.REACT_APP_PUSHER_APP_KEY,
            protocol: 'ws',
            cluster: "mt1",
            wsHost: process.env.REACT_APP_WS_HOST,
            wsPort: process.env.REACT_APP_WS_PORT,
            wssHost: process.env.REACT_APP_WS_HOST,
            wssPort: process.env.REACT_APP_WS_PORT,
            enabledTransports: ['ws', 'wss'],
            encrypted: true,
            forceTLS: true,
            disableStats: true,
        }

        let body = document.body;
        if(this.props.isLoggedIn && this.props.user && this.props.user_mode == 1)
        {
            body.classList.remove("sellerTheme");
            body.classList.add("buyerTheme");
        }
        else {
            body.classList.remove("buyerTheme");
            body.classList.add("sellerTheme");
        }

        if(this.props.isLoggedIn)
        {
            // this.connectWebSocket();
            if(!isDevEnv())
            {
                LSQSessionTrack(this.props.user)
            }
            ReactGA.set({ userId: this.props.user._id });

            // echoProps.auth = {
            //     headers: {
            //         'token': getUserToken()
            //     }
            // }
            // echoProps.authEndpoint = config.base_url + '/broadcasting/auth?token=' + getUserToken()

            // window.Echo = new Echo(echoProps);

        }


        window.addEventListener('beforeunload', function (e) {
            localStorage.removeItem('LSQ_SESSION_KEY');
        });
    }

    connectWebSocket = () => {
        socket.connect();
        socket.emit('joinRoom', 'tempCommRoom1');
        // socket.emit('joinRoom', "roomId_usr_" + this.props.user.id);
        // socket.on('joinedRoom', (room) => {
        //     console.log({joinedRoom: room});
        //     // socket.emit('msgToRoom', {room, message: {title: "title", body: "body"}});
        // });
        // socket.on('msgToClient', (message) => {
        //     console.log('msgToClient', message);
        // });
        // const userToken = getUserToken();
        // if(userToken) {
        //     connectSocket(userToken);
        // }
    }

    loadAvailableLanguages = async () => {
        try {
            const res = await Extras.getAvailableLanguages();

            if (res.data.status && res.data.status == "success") {
                this.props.loadAvailableLanguages(res.data.data.languages);
                // this.loadLanguageSupport();
            }
        } catch (e) {
            console.error(e);
        }
    };

    loadLanguageSupport = () => {
        var languageCode = localStorage.getItem("language");

        if (languageCode == null) {
            languageCode = "en";
            localStorage.setItem("language", languageCode);
        }

        if (languageCode != "en") {
            this.props.loadLanguageTranslations(languageCode);
        }
    };


    render() {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider
                    theme={
                        this.props.isLoggedIn &&
                        this.props.user &&
                        this.props.user_mode == 1
                            ? buyerTheme
                            : sellerTheme
                    }
                >
                    <SnackbarProvider maxSnack={4}>
                        {this.props.isLoading && <Loader />}
                        {this.props.topProgressBar && (
                            <LinearProgress
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    top: "0",
                                }}
                            />
                        )}
                        <CssBaseline />
                        <BrowserRouter>
                            <React.Suspense fallback={<div>Loading...<Loader /></div>}>
                                <SwitchRoutes />
                            </React.Suspense>
                            <React.Suspense fallback={<></>}>
                                {/* <FirebaseNotification /> */}
                                {/* <UnauthorizedAndPendingGSTModal /> */}
                                {/* <TopBidOfferSuggestionContainer /> */}
                                {/* <FloatingContactForm /> */}
                            </React.Suspense>
                            <React.Suspense fallback={<></>}>
                                {/* <ToastNotification /> */}
                            </React.Suspense>
                            <React.Suspense fallback={<></>}>
                                {/* <ScrollToTop /> */}
                            </React.Suspense>
                        </BrowserRouter>
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        user_mode: state.userReducer.user_mode,
        isLoggedIn: state.userReducer.isLoggedIn,
        loginStatus: state.userReducer.loginStatus,
        isLoading: state.commonReducer.loader,
        topProgressBar: state.commonReducer.topProgressBar,
    };
};

export default connect(mapStateToProps, {
    checkAndLoadUser,
    loader,
    loadCommonData,
    loadLanguageTranslations,
    loadAvailableLanguages,
    topProgressBarStatus,
})(CoalShastra);
