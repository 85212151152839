import { LOAD_COMMON_DATA, LOADER, LOGIN_MODAL, SIGNUP_MODAL, LANGUAGE, LANGUAGES, TOP_PROGRESSBAR } from '../actions/types';

const initialState = {
	loader: false,
	topProgressBar: false,
	loginModal: false,
	signupModal: false,
	countries: [],
	coalTypes: [],
	mineCompanies: [],
    states: [],
    languageCode: 'en',
    translations: {},
    availableLanguages: [],
	globalSettings: {},
	paymentTerms: [],
	ekycIndustries:[]
}

export default function commonReducer(state = initialState, action) {
	switch (action.type) {
		case LOAD_COMMON_DATA:
			return {
				...state,
				countries: action.payload.countries,
				coalTypes: action.payload.coalTypes,
				mineCompanies: action.payload.mineCompanies,
				states: action.payload.states,
				globalSettings: action.payload.globalSettings,
				paymentTerms: action.payload.paymentTerms,
				ekycIndustries: action.payload.ekycIndustries
            }
        case LANGUAGES:
            return {
                ...state,
                availableLanguages: action.payload.availableLanguages
            }
        case LANGUAGE:
            return {
                ...state,
                languageCode: action.payload.languageCode,
                translations: action.payload.translations
            }
		case LOADER:
            return {
                ...state,
                loader: action.payload.loader
			}
		case TOP_PROGRESSBAR:
            return {
                ...state,
                topProgressBar: action.payload.topProgressBar
			}
		case LOGIN_MODAL:
			return {
				...state,
				loginModal: action.payload.loginModal
			}
		case SIGNUP_MODAL:
			return {
				...state,
				signupModal: action.payload.signupModal
			}
		default:
			return state;
	}
}
